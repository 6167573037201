import Vue from 'vue'

const vue = Vue.prototype

const main = [
  {
    title: 'Home',
    route: 'home',
    icon: 'home',
    pack: 'fas',
    visible: true,
  }, {
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'tachometer-alt',
    pack: 'fas',

    visible: vue.haveAccess('Dashboard'),
  },
  {
    title: 'Users',
    route: 'user.list',
    icon: 'users',
    pack: 'fas',
    visible: vue.haveAccess(['User List', 'User List Owner']),
  },
  {
    title: 'Roles',
    route: 'role.list',
    icon: 'user-lock',
    pack: 'fas',
    visible: vue.haveAccess(['Role List', 'Role List Owner']),
  },
  {
    title: 'Setting',
    route: 'settings',
    icon: 'cogs',
    pack: 'fas',
    visible: vue.haveAccess(),
  },
  {
    title: 'Transport',
    icon: 'TruckIcon',
    pack: 'fas',
    children: [
      {
        title: 'tbackgrounds',
        route: 'tbackground.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Tbackground List']),
      },
      {
        title: 'tvideo',
        route: 'tvideo.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Tvideo List']),
      },
      {
        title: 'ticons',
        route: 'ticon.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['ticon List']),
      },
      {
        title: 'tgalleries',
        route: 'tgallery.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Tgallery List']),
      },
      {
        title: 'tpreviews',
        route: 'tpreview.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Tpreview List']),
      },
      {
        title: 'ttnews',
        route: 'ttnew.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Ttnew List']),
      },
      {
        title: 'tdecisions',
        route: 'tdecision.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Tdecision List']),
      },
    ],
  },
  {
    title: 'HOME',
    icon: 'HomeIcon',
    pack: 'fas',
    children: [
      {
        title: 'slaydmains',
        route: 'slaydmain.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Slaydmain List']),
      },
      {
        title: 'tnews',
        route: 'tnew.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Tnew List']),
      },
      // {
      //   title: 'mquestions',
      //   route: 'mquestion.list',
      //   icon: 'file-invoice',
      //   pack: 'fas',
      //   visible: vue.haveAccess(['Mquestion List']),
      // },
      {
        title: 'galleries',
        route: 'gallery.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Gallery List']),
      },
      {
        title: 'videomains',
        route: 'videomain.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Videomain List']),
      },
      {
        title: 'achives',
        route: 'achive.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Achive List']),
      },
      {
        title: 'statistics',
        route: 'statistic.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Statistic List']),
      },
      {
        title: 'icons',
        route: 'icon.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Icon List']),
      },
      {
        title: 'preview',
        route: 'preview.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Preview List']),
      },
      {
        title: 'decisions',
        route: 'decision.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Decision List']),
      },
    ],
  },
  {
    title: 'idlib',
    icon: 'MapPinIcon',
    pack: 'fas',
    children: [
      {
        title: 'galleryidlib',
        route: 'galleryidlib.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Galleryidlib List']),
      },
      {
        title: 'tnewidlib',
        route: 'tnewidlib.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Tnewidlib List']),
      },
      {
        title: 'connectidlib',
        route: 'connectidlib.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Connectidlib List']),
      },
      {
        title: 'videoidlib',
        route: 'videoidlib.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Videoidlib List']),
      },
      {
        title: 'idlibstatistics',
        route: 'idlibstatistic.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Idlibstatistic List']),
      },
      {
        title: 'idlibdecisions',
        route: 'idlibdecision.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Idlibdecision List']),
      },
      {
        title: 'idlibbackgrounds',
        route: 'idlibbackground.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Idlibbackground List']),
      },
      {
        title: 'idlibmquestions',
        route: 'idlibmquestion.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Idlibmquestion List']),
      },
      {
        title: 'idlibicons',
        route: 'idlibicon.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Idlibicon List']),
      },
    ],
  },
  {
    title: 'HALAB',
    icon: 'MapPinIcon',
    pack: 'fas',
    children: [
      {
        title: 'galleryhalab',
        route: 'galleryhalab.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Galleryhalab List']),
      },
      {
        title: 'videohalab',
        route: 'videohalab.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Videohalab List']),
      },
      {
        title: 'connecthalab',
        route: 'connecthalab.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Connecthalab List']),
      },
      {
        title: 'tnewhalab',
        route: 'tnewhalab.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Tnewhalab List']),
      },
      {
        title: 'halabdecisions',
        route: 'halabdecision.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Halabdecision List']),
      },
      // {
      //   title: 'halabdecisions',
      //   route: 'halabdecision.list',
      //   icon: 'file-invoice',
      //   pack: 'fas',
      //   visible: vue.haveAccess(['Halabdecision List']),
      // },
      {
        title: 'halabmquestions',
        route: 'halabmquestion.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Halabmquestion List']),
      },
      {
        title: 'halabicons',
        route: 'halabicon.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Halabicon List']),
      },
      {
        title: 'halabbackgrounds',
        route: 'halabbackground.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Halabbackground List']),
      },
      {
        title: 'halabstatistics',
        route: 'halabstatistic.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Halabstatistic List']),
      },
    ],
  },
  {
    title: 'JSR',
    icon: 'MapPinIcon',
    pack: 'fas',
    children: [
      {
        title: 'galleryjsr',
        route: 'galleryjsr.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Galleryjsr List']),
      },
      {
        title: 'videojsr',
        route: 'videojsr.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Videojsr List']),
      },
      {
        title: 'connectjsr',
        route: 'connectjsr.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Connectjsr List']),
      },
      {
        title: 'tnewjsr',
        route: 'tnewjsr.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Tnewjsr List']),
      },
      {
        title: 'jsrdecisions',
        route: 'jsrdecision.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Jsrdecision List']),
      },
      {
        title: 'jsrmquestions',
        route: 'jsrmquestion.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Jsrmquestion List']),
      },
      {
        title: 'jsricons',
        route: 'jsricon.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Jsricon List']),
      },
      {
        title: 'jsrbackgrounds',
        route: 'jsrbackground.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Jsrbackground List']),
      },
      {
        title: 'jsrstatistics',
        route: 'jsrstatistic.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Jsrstatistic List']),
      },
    ],
  },
  {
    title: 'cimport',
    icon: 'MapPinIcon',
    pack: 'fas',
    children: [
      {
        title: 'gallerycimport',
        route: 'gallerycimport.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Gallerycimport List']),
      },
      {
        title: 'videocimport',
        route: 'videocimport.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Videocimport List']),
      },
      {
        title: 'connectcimport',
        route: 'connectcimport.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Connectcimport List']),
      },
      {
        title: 'cimportstatistics',
        route: 'cimportstatistic.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Cimportstatistic List']),
      },
      {
        title: 'tnewcimport',
        route: 'tnewcimport.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Tnewcimport List']),
      },
      {
        title: 'cimportdecisions',
        route: 'cimportdecision.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Cimportdecision List']),
      },
      {
        title: 'cimportmquestions',
        route: 'cimportmquestion.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Cimportmquestion List']),
      },
      {
        title: 'cimporticons',
        route: 'cimporticon.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Cimporticon List']),
      },
      {
        title: 'cimportbackgrounds',
        route: 'cimportbackground.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Cimportbackground List']),
      },
      // {
      //   title: 'cimportstatistics',
      //   route: 'cimportstatistic.list',
      //   icon: 'file-invoice',
      //   pack: 'fas',
      //   visible: vue.haveAccess(['Cimportstatistic List']),
      // },
    ],
  },
  {
    title: 'harem',
    icon: 'MapPinIcon',
    pack: 'fas',
    children: [
      {
        title: 'connectharem',
        route: 'connectharem.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Connectharem List']),
      },
      {
        title: 'galleryharem',
        route: 'galleryharem.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Galleryharem List']),
      },
      {
        title: 'videoharem',
        route: 'videoharem.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Videoharem List']),
      },
      {
        title: 'haremstatistics',
        route: 'haremstatistic.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Haremstatistic List']),
      },
      {
        title: 'tnewharem',
        route: 'tnewharem.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Tnewharem List']),
      },
      {
        title: 'haremdecisions',
        route: 'haremdecision.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Haremdecision List']),
      },
      {
        title: 'haremmquestions',
        route: 'haremmquestion.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Haremmquestion List']),
      },
      {
        title: 'haremicons',
        route: 'haremicon.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Haremicon List']),
      },
      {
        title: 'harembackgrounds',
        route: 'harembackground.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Harembackground List']),
      },
    ],
  },
  {
    title: 'driving',
    icon: 'NavigationIcon',
    pack: 'fas',
    children: [
      {
        title: 'drgallery',
        route: 'drgallery.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Drgallery List']),
      },
      {
        title: 'statisticdr',
        route: 'statisticdr.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Statisticdr List']),
      },
      {
        title: 'drnew',
        route: 'drnew.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Drnew List']),
      },
      {
        title: 'drdecision',
        route: 'drdecision.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Drdecision List']),
      },
      {
        title: 'videodriving',
        route: 'videodriving.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Videodriving List']),
      },
      {
        title: 'slayddriving',
        route: 'slayddriving.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Slayddriving List']),
      },
    ],
  },
  {
    title: 'School',
    icon: 'BriefcaseIcon',
    pack: 'fas',
    children: [
      {
        title: 'schools',
        route: 'school.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['School List']),
      },
      {
        title: 'newschools',
        route: 'newschool.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Newschool List']),
      },
      {
        title: 'sprices',
        route: 'sprice.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Sprice List']),
      },
      {
        title: 'sicon',
        route: 'sicon.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Sicon List']),
      },
    ],
  },
  {
    title: 'Licenses1',
    icon: 'AwardIcon',
    pack: 'fas',
    children: [
      {
        title: 'achiveLicensecondition',
        route: 'achiveLicensecondition.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['AchiveLicensecondition List']),
      },
      {
        title: 'achiveLicense',
        route: 'achiveLicense.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['AchiveLicense List']),
      },
      {
        title: 'privewLicense',
        route: 'privewLicense.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['PrivewLicense List']),
      },
      {
        title: 'tnewLicense',
        route: 'tnewLicense.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['TnewLicense List']),
      },
      {
        title: 'decisionLicense',
        route: 'decisionLicense.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['DecisionLicense List']),
      },
      {
        title: 'backgroundlicense',
        route: 'backgroundlicense.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Backgroundlicense List']),
      },
    ],
  },
  // {
  //   title: 'tests',
  //   icon: 'CheckCircleIcon',
  //   pack: 'fas',
  //   children: [
  //   ],
  // },
  {
    title: 'Publictransport',
    icon: 'MapIcon',
    pack: 'fas',
    children: [
      {
        title: 'lines',
        route: 'line.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Line List']),
      },
      {
        title: 'statisticPublictransport',
        route: 'statisticPublictransport.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['StatisticPublictransport List']),
      },
      {
        title: 'videoPublictransport',
        route: 'videoPublictransport.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['VideoPublictransport List']),
      },
      {
        title: 'achivePublictransport',
        route: 'achivePublictransport.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['AchivePublictransport List']),
      },
      {
        title: 'iconPublictransport',
        route: 'iconPublictransport.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['IconPublictransport List']),
      },
      {
        title: 'previewPublictransport',
        route: 'previewPublictransport.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['PreviewPublictransport List']),
      },
      {
        title: 'tnewPublictransport',
        route: 'tnewPublictransport.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['TnewPublictransport List']),
      },
      {
        title: 'decisionPublictransport',
        route: 'decisionPublictransport.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['DecisionPublictransport List']),
      },
    ],
  },
  {
    title: 'Certificate',
    icon: 'UserCheckIcon',
    pack: 'fas',
    children: [
      {
        title: 'decisionCertificate',
        route: 'decisionCertificate.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['DecisionCertificate List']),
      },
      {
        title: 'text',
        route: 'text.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Text List']),
      },
      {
        title: 'backgroundCertificate',
        route: 'backgroundCertificate.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['BackgroundCertificate List']),
      },
      {
        title: 'tnewCertificate',
        route: 'tnewCertificate.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['TnewCertificate List']),
      },
      {
        title: 'practical_test',
        route: 'practicalTest.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Practical_test List']),
      },
      {
        title: 'medical_test',
        route: 'medicalTest.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Medical_test List']),
      },
      {
        title: 'theory_test',
        route: 'theoryTest.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Theory_test List']),
      },
      {
        title: 'first_registration',
        route: 'firstRegistration.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['First_registration List']),
      },
      {
        title: 'second_registration',
        route: 'secondRegistration.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Second_registration List']),
      },
    ],
  },
  // {
  //   title: 'registration',
  //   icon: 'FilePlusIcon',
  //   pack: 'fas',
  //   children: [
  //   ],
  // },
  {
    title: 'media',
    icon: 'FilePlusIcon',
    pack: 'fas',
    children: [
      {
        title: 'around us',
        route: 'around.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Around List']),
      },
      {
        title: 'employee',
        route: 'employee.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Employee List']),
      },
      {
        title: 'connect',
        route: 'connect.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Connect List']),
      },
      {
        title: 'mnew',
        route: 'mnew.list',
        icon: 'file-invoice',
        pack: 'fas',
        visible: vue.haveAccess(['Mnew List']),
      },
    ],
  },
  {
    title: 'appoinment',
    route: 'appoinment.list',
    icon: 'file-invoice',
    pack: 'fas',
    visible: vue.haveAccess(['Appoinment List']),
  },
  {
    title: 'karaj',
    route: 'karaj.list',
    icon: 'file-invoice',
    pack: 'fas',
    visible: vue.haveAccess(['Karaj List']),
  },
  {
    title: 'achiveAbout',
    route: 'achiveAbout.list',
    icon: 'file-invoice',
    pack: 'fas',
    visible: vue.haveAccess(['AchiveAbout List']),
  },
  {
    title: 'tcompanies',
    route: 'tcompany.list',
    icon: 'file-invoice',
    pack: 'fas',
    visible: vue.haveAccess(['Tcompany List']),
  },
]
export default main
